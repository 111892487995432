<template lang='pug'>
div
  v-container(fluid, justify-center, align-center)
    v-tabs(v-model="activeTab", touchless)
      v-tab(v-for="tab in tabs", :key="tab.key")
        span {{ tab.label }}
      v-tabs-items(v-model="activeTab")
        v-tab-item(v-for="tab in tabs", :key="tab.key")
          DataFilter.ma-1.pa-2(
            :filterProps="filters",
            :items="activeDateset",
            :paginate="false"
          )
            template(v-slot:actions)
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.ml-2.mr-2.mt-1(
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="getData"
                  )
                    v-icon mdi mdi-refresh
                span Atualizar

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.ml-2.mt-1(
                    v-if="isGerente && activeTab === 1",
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="newCadastroDialog"
                  )
                    v-icon mdi mdi-plus
                span Adicionar

              v-divider(vertical, v-if="isGerente")

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.mr-5.mt-1(
                    v-if="isGerente",
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="dialogEnviar = true"
                  )
                    v-icon mdi mdi-cube-send
                span Forçar Envio

            template(v-slot:default="{ items }")
              v-data-table.reinfTable(
                :headers="activeHeaders",
                :items="items",
                item-key="id",
                dense,
                :footer-props="footerProps",
                :loading="loading"
              )
                template(v-slot:item.actions="{ item }")
                  td.text-center.pr-2.pl-0
                    v-tooltip(bottom, v-if="isGerente")
                      template(v-slot:activator="{ on }")
                        v-btn(
                          v-on="on",
                          color="primary",
                          fab,
                          small,
                          icon,
                          @click="editCadastroDialog(item)"
                        )
                          v-icon mdi mdi-pencil-circle-outline
                      span Editar














  v-dialog(v-model="dialogEnviar", persistent, max-width="500")
    v-card(style="width: 500px")
      v-card-title
        h3 Forçar envio de dados
      v-card-text.mt-4
        div(style="font-size: 14px") Selecione um cliente para encaminhar o comando de atualização das tabelas do CSRT. Este comando atualiza todas as tabelas desta funcionalidade.

        v-autocomplete.mt-4(
          v-model="clienteSelected",
          :items="datasetClientes",
          :disabled="datasetClientes.length === 0",
          :loading="datasetClientes.length === 0",
          placeholder="Digite ou selecione um cliente",
          return-object,
          item-text="nome",
          item-value="idcliente",
          label="Cliente",
          dense,
          outlined,
          hide-details
        )

      v-card-actions.pa-2.pt-0.pr-1.pb-4
        v-spacer
        v-btn(color="primary", outlined, @click="dialogEnviar = false")
          span Cancelar
        v-btn(
          color="primary",
          :disabled="dialogEnviarLoadingSend",
          outlined,
          @click="sendCliente()"
        )
          span Enviar

  v-dialog(v-model="dialogCadastrarEditar", persistent, max-width="850")
    v-card(style="width: 850px")
      v-card-title
        h3 {{ tabs[activeTab].label }} - {{ dialogCadastrarEditarType }}
      v-card-text.pt-10(:style="cardTextStyle")
        v-form(ref="form")
          v-row
            v-col.ma-0.pa-2.py-1.text-right(
              :cols="12",
              :md="header.cols",
              v-for="header in filteredHeaders",
              :key="header.value"
            )
              v-select(
                v-if="['siglaestado', 'softhouse'].includes(header.value)",
                v-model="itemManipulate[header.value]",
                :items="header.value === 'siglaestado' ? selectEstados : selectSoftHouse",
                item-text="nome",
                item-value="idcliente",
                :label="header.text",
                outlined,
                :disabled="['siglaestado', 'softhouse'].includes(header.value) && dialogCadastrarEditarType === 'Alteração'",
                dense
              )

              v-text-field(
                v-else,
                v-model="itemManipulate[header.value]",
                :label="header.text",
                outlined,
                :disabled="['id', 'empresa'].includes(header.value)",
                dense
              )

      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="closeCadastroDialog")
          span Cancelar
        v-btn(color="success", outlined, @click="saveCadastro")
          span Salvar

  v-dialog(v-model="dialogExcluir", max-width="600px")
    v-card
      v-card-title
        h3 Excluir registro
      v-card-text
        h5.ma-0.pa-0 Deseja realmente excluir a registro?
      v-card-actions.mr-0
        v-spacer
        v-btn(outlined, color="primary", @click="deleteRegistroDialogClose")
          span Cancelar
        v-btn(
          outlined,
          color="error",
          @click="deleteRegistroExecute()",
          :disabled="dialogExcluirInProcess"
        )
          span Excluir
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { clientes as api } from "@/api";

export default {
  name: "reinf",
  data: () => ({
    loading: false,
    isGerente: true,
    tabs: [
      { key: 0, label: "SoftHouse" },
      { key: 1, label: "Responsável técnico por UF" },
    ],
    activeTab: 0,
    activeHeaders: [],
    activeDateset: [{}],
    datasetClientes: [],
    dialogEnviar: false,
    dialogEnviarLoadingSend: false,
    clienteSelected: null,
    footerProps: {
      itemsPerPageOptions: [50, 100, 300, 500, -1],
      itemsPerPageAllText: "Todos",
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageText: "Itens por página",
    },
    filters: {
      includeSearch: true,
      items: [],
      searchText: "",
      useSearchHash: false,
    },
    dialogCadastrarEditar: false,
    dialogCadastrarEditarType: "Inclusão",
    itemManipulate: {},
    dialogExcluir: false,
    dialogExcluirInProcess: false,
    selectSoftHouse: ["SYSAFRA", "CONTROLSOFT"],
    selectEstados: [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ],
  }),
  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    async fetchData(url) {
      try {
        this.activeDateset = [{}];
        this.loading = true;

        const response = await csapi.get(url);
        this.activeDateset = this.formatDateGetData(response.data);
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao requisitar dados: ${error.message}`);
      } finally {
        this.loading = false;
      }
    },

    getData() {
      const actions = {
        0: this.getHeadersKey0, // Tipos de Serviços
        1: this.getHeadersKey1, // Natureza de Rendimentos
      };
      const action = actions[this.activeTab] || this.getHeadersKey0;
      action.call(this);
    },

    formatDateGetData(data) {
      data.forEach((item) => {
        if (item.dtvalidadeini) {
          item.dtvalidadeini = item.dtvalidadeini
            .split("-")
            .reverse()
            .join("/");
        }

        if (item.dtvalidadefim) {
          item.dtvalidadefim = item.dtvalidadefim
            .split("-")
            .reverse()
            .join("/");
        }
      });
      return data;
    },

    createActionColumn() {
      this.activeHeaders.push({
        text: "Ações",
        value: "actions",
        width: "100",
        sortable: false,
        cols: 12,
      });
    },

    getHeadersKey0() {
      this.activeHeaders = [
        { text: "ID", value: "empresa", width: "70", cols: 2 },
        { text: "SoftHouse", value: "softhouse", width: "150", cols: 3 },
        { text: "Nome", value: "nome", width: "280", cols: 7 },
        { text: "Razão Social", value: "razaosocial", width: "280", cols: 9 },
        { text: "Telefone", value: "telefone", width: "180", cols: 3 },
        { text: "Site", value: "site", width: "300", cols: 6 },
        { text: "Email contato", value: "emailcontato", width: "300", cols: 6 },
        { text: "Email suporte", value: "emailsuporte", width: "300", cols: 6 },
        {
          text: "Telefone suporte",
          value: "telefonesuporte",
          width: "200",
          cols: 6,
        },
        { text: "URL suporte", value: "urlsuporte", width: "350", cols: 6 },
        { text: "URL release", value: "urlrelease", width: "350", cols: 6 },
        { text: "CNPJ", value: "respteccnpj", width: "160", cols: 3 },
        {
          text: "Telefone",
          value: "resptectelefone",
          width: "160",
          cols: 3,
        },
        {
          text: "Contato",
          value: "respteccontato",
          width: "200",
          cols: 3,
        },
        {
          text: "Email",
          value: "resptecemail",
          width: "350",
          cols: 3,
        },
        {
          text: "CSRT ID",
          value: "resptecidcsrt",
          width: "180",
          cols: 3,
        },
        {
          text: "CSRT Hash",
          value: "resptechashcsrt",
          width: "350",
          cols: 6,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getHeadersKey1() {
      this.activeHeaders = [
        { text: "ID", value: "id", width: "70", cols: 2 },
        { text: "UF", value: "siglaestado", width: "80", cols: 2 },
        { text: "SoftHouse", value: "softhouse", width: "140", cols: 4 },
        { text: "CNPJ", value: "respteccnpj", width: "160", cols: 4 },
        {
          text: "Contato",
          value: "respteccontato",
          width: "220",
          cols: 4,
        },
        { text: "Email", value: "resptecemail", width: "280", cols: 4 },
        {
          text: "Telefone",
          value: "resptectelefone",
          width: "200",
          cols: 4,
        },
        {
          text: "CSRT Produção - ID",
          value: "resptecidcsrt",
          width: "210",
          cols: 4,
        },
        {
          text: "CSRT Produção - Hash",
          value: "resptechashcsrt",
          width: "420",
          cols: 8,
        },
        {
          text: "CSRT Homologação - ID",
          value: "resptecidcsrthml",
          width: "240",
          cols: 4,
        },
        {
          text: "CSRT Homologação - Hash",
          value: "resptechashcsrthml",
          width: "420",
          cols: 8,
        },
        {
          text: "Validade ínicio",
          value: "dtvalidadeini",
          width: "180",
          cols: 3,
        },
        { text: "Validade fim", value: "dtvalidadefim", width: "180", cols: 3 },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    async getDataClientes() {
      api.refreshAuthProd(this.$store.state.auth);

      try {
        this.datasetClientes = await api.getAll();
        this.datasetClientes.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
      } catch (e) {
        this.$toast.error("Ocorreu algum problema ao buscar as informações");
      }
    },

    async sendCliente() {
      this.dialogEnviarLoadingSend = true;

      // Verificar se o cliente foi selecionado
      if (!this.clienteSelected) {
        let msg = "Selecione um cliente para enviar o comando";

        this.$toast.error(msg);
        this.dialogEnviarLoadingSend = false;
        return;
      }

      // Verificar se o cliente está na versão correta para enviar
      let cversio = this.clienteSelected.versao;
      if (this.compareVersions(cversio, "2025.2.1.4") <= 0) {
        let msg = "Server do cliente está em uma versão antiga";
        msg += " e não suporta essa funcionalidade";

        this.$toast.error(msg);
        this.dialogEnviarLoadingSend = false;
        return;
      }

      try {
        const link = `/v1/databridge/integrate`;
        const data = {
          hash: [this.clienteSelected.hash],
          processo: "csrt",
        };

        const response = await csapi.post(link, data);

        if (response.status.toString().startsWith("2")) {
          this.$toast.success("Encaminhado com sucesso");
          this.clienteSelected = null;
          this.dialogEnviar = false;
        }
      } catch (error) {
        let msg = error.response.data.message;
        if (error.response.data.errors) {
          msg = error.response.data.errors[0].message;
        }
        this.$toast.error("Erro ao encaminhar registro. msg server: " + msg);
      }

      this.dialogEnviarLoadingSend = false;
    },

    deleteRegistroDialogOpen(item) {
      this.dialogExcluir = true;
      this.itemManipulate = JSON.parse(JSON.stringify(item));
    },

    deleteRegistroDialogClose() {
      this.dialogExcluir = false;
      this.itemManipulate = {};
    },

    deleteRegistroExecute() {
      try {
        this.dialogExcluirInProcess = true;
        let link = this.getLink(this.activeTab);
        let id = this.itemManipulate.id;

        csapi.delete(`${link}/${id}`).then((res) => {
          if (res.status.toString().startsWith("2")) {
            this.$toast.success("Registro excluído com sucesso");
            this.dialogExcluir = false;
            this.itemManipulate = {};
            this.getData();
          } else {
            this.$toast.error("Erro ao excluir registro");
          }
        });
      } catch (error) {
        this.$toast.error("Erro ao excluir registro");
      } finally {
        this.dialogExcluirInProcess = false;
      }
    },

    adminRole() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const adminRole = [];

      roles.forEach((role) => {
        if (role.name.toUpperCase() === "GERENTE") {
          adminRole.push(role);
        }
      });

      this.isGerente = adminRole.length > 0;
    },

    newCadastroDialog() {
      this.dialogCadastrarEditarType = "Inclusão";
      this.dialogCadastrarEditar = true;

      this.itemManipulate = this.activeHeaders.reduce((acc, header) => {
        if (!["criadoem", "atualizadoem", "actions"].includes(header.value)) {
          acc[header.value] = null;
        }
        return acc;
      }, {});
    },

    editCadastroDialog(item) {
      this.dialogCadastrarEditarType = "Alteração";
      this.dialogCadastrarEditar = true;

      this.itemManipulate = JSON.parse(JSON.stringify(item));
    },

    closeCadastroDialog() {
      this.dialogCadastrarEditar = false;
      this.itemManipulate = {};

      this.dialogCadastrarEditarType = "Inclusão";
    },

    getLink(tab) {
      const links = {
        0: "/v1/databridge/config-softhouse",
        1: "/v1/databridge/config-softhouse-uf",
      };
      return links[tab] || "/v1/databridge/config-softhouse";
    },

    async saveCadastro() {
      let link = this.getLink(this.activeTab);

      try {
        let data = JSON.parse(JSON.stringify(this.itemManipulate));
        let keysToNumber = ["resptecidcsrt", "resptecidcsrthml"];

        // Verificar os campos que estão vazios e transformar em null
        for (let key in data) {
          if (typeof data[key] === "string") {
            data[key] = data[key].trim();
          }

          if (keysToNumber.includes(key)) {
            data[key] = parseInt(data[key]);
          }

          if (data[key] === "") {
            data[key] = null;
          }
        }

        if (data.dtvalidadeini && data.dtvalidadeini.trim() !== "") {
          if (data.dtvalidadeini.length !== 10) {
            this.$toast.error("Data início inválida");
            return;
          }

          let dtvalidadeinitest = new Date(
            data.dtvalidadeini.split("/").reverse().join("-")
          );
          if (isNaN(dtvalidadeinitest.getTime())) {
            this.$toast.error("Data início inválida");
            return;
          }
          data.dtvalidadeini = data.dtvalidadeini
            .split("/")
            .reverse()
            .join("-");
        } else {
          data.dtvalidadeini = "";
        }

        if (data.dtvalidadefim && data.dtvalidadefim.trim() !== "") {
          if (data.dtvalidadefim.length !== 10) {
            this.$toast.error("Data fim inválida");
            return;
          }

          let dtvalidadefimtest = new Date(
            data.dtvalidadefim.split("/").reverse().join("-")
          );
          if (isNaN(dtvalidadefimtest.getTime())) {
            this.$toast.error("Data fim inválida");
            return;
          }
          data.dtvalidadefim = data.dtvalidadefim
            .split("/")
            .reverse()
            .join("-");
        } else {
          data.dtvalidadefim = "";
        }

        if (this.dialogCadastrarEditarType === "Alteração") {
          const registroOriginal = this.activeDateset.find(
            (item) => item.id === this.itemManipulate.id
          );

          if (
            registroOriginal &&
            registroOriginal.dtvalidadefim &&
            registroOriginal.dtvalidadefim.trim() !== "" &&
            (!data.dtvalidadefim || data.dtvalidadefim.trim() === "")
          ) {
            this.$toast.error(
              "Não é permitido remover a data fim quando já existente"
            );
            return;
          }
        }

        // Verificar se é inclusão ou alteração e chamar a API
        let res;
        if (this.activeTab === 0) {
          res = await csapi.patch(`${link}/${data.softhouse}`, data);
        } else {
          res =
            this.dialogCadastrarEditarType === "Inclusão"
              ? await csapi.post(link, data)
              : await csapi.patch(`${link}/${data.id}`, data);
        }

        // Verificar o status da resposta e exibir mensagem
        if (res.status.toString().startsWith("2")) {
          const msg =
            this.dialogCadastrarEditarType === "Inclusão"
              ? "incluído"
              : "alterado";
          this.$toast.success("Registro CSRT " + msg + " com sucesso");

          this.dialogCadastrarEditar = false;
          this.dialogCadastrarEditarType = "Inclusão";
          this.itemManipulate = {};

          setTimeout(() => this.getData(), 1000);
        } else {
          this.$toast.error("Erro ao salvar registro");
        }
      } catch (error) {
        try {
          let message = error.response.data.errors[0];
          console.error("message", message);
          this.$toast.error(
            `Validação de gravação via API: Campo: \n${message.path[0]} - ${message.message}`
          );
        } catch (e) {
          let message = error.response.data.message;
          this.$toast.error(`Validação de gravação via API: \n${message}`);
        }
      }
    },

    compareVersions(v1, v2) {
      const v1Parts = v1.split(".").map(Number);
      const v2Parts = v2.split(".").map(Number);

      for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
        const v1Part = v1Parts[i] || 0;
        const v2Part = v2Parts[i] || 0;

        if (v1Part > v2Part) return 1;
        if (v1Part < v2Part) return -1;
      }

      return 0;
    },
  },

  mounted() {
    // Cabeçalho
    this.isControl(true);
    this.setTitle("Responsável Técnico");

    // Regras de acesso para gerente
    this.adminRole();
    if (this.isGerente) this.createActionColumn();

    // Carrega os dados
    this.getData();
    this.getDataClientes();
  },

  computed: {
    filteredHeaders() {
      const keys = ["dhatualuzadoapi", "actions"];
      return this.activeHeaders.filter(
        (header) => !keys.includes(header.value)
      );
    },

    cardTextStyle() {
      return {
        maxHeight: "calc(100vh - 300px)",
        overflowY: "auto",
      };
    },
  },

  watch: {
    activeTab() {
      this.getData();
    },
  },
};
</script>

<style>
.reinfTable .v-data-table__wrapper {
  height: calc(100vh - 290px);
  overflow-y: auto;
}
</style>
